export { default as ContentPageProductInfo } from './ProductInfo'

export { default as ContentPageAccordion } from './Accordion'
export { default as ContentPageAudio } from './Audio'
export { default as ContentPageFile } from './File'
export { default as ContentPageGridImage } from './GridImage'
export { default as ContentPageImageCarousel } from './ImageCarousel'
export { default as ContentPageLinkButton } from './LinkButton'
export { default as ContentPageListImage } from './ListImage'
export { default as ContentPagePlainText } from './PlainText'
export { default as ContentPageSingleImage } from './SingleImage'
export { default as ContentPageVideo } from './Video'
export { default as ContentPageWhatsAppButton } from './WhatsAppButton'
export { default as ContentPageYouTubeVideo } from './YoutubeVideo'
export { default as ContentPageYouTubePlaylist } from './YoutubePlaylist'
export { default as ContentPageAudioPlaylist } from './AudioPlaylist'
export { default as ContentPageVideoPlaylist } from './VideoPlaylist'
export { default as ContentPageClassTraining } from './ClassTraining'
export { default as ContentPageClassTrainingV2 } from './ClassTrainingV2'
export { default as ContentPageStoryChapter } from './StoryChapter'

import { useState, useEffect } from 'react'
import NextLink from 'next/link'
// @mui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  ListItemButton,
  ListItemText,
  ListItem,
  ListItemIcon
} from '@mui/material'
import {
  AttachFileRounded,
  ExpandMoreOutlined,
  InsertLinkRounded,
  InsertPhotoOutlined,
  OndemandVideoRounded,
  OpenInNew,
  TextSnippetOutlined,
  VideocamOffOutlined
} from '@mui/icons-material'
import { styled } from '@mui/material/styles'
// components
import AccessFileButton from '~/components/AccessFileButton'
import TextWithSoftBreaks from '~/components/TextWithSoftBreaks'
import NativeImage from '~/components/NativeImage'
import VideoPlayer from '~/components/VideoPlayer'

// i18n
import { msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
// utils
import { getYouTubeVideoId } from '~/libs/youtube'

// page specific
import { ContentPageSection } from '../components'
import { GridCarousel } from '~/components/carousel'
import fallbackImage from '~/libs/fallbackImage'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const ContentArea = styled('div')(({ theme, bgcolor, borderRadius }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%',
  backgroundColor: bgcolor || theme.palette.grey[300],
  borderRadius: borderRadius || theme.spacing(2),
  overflow: 'hidden'
}))

const YoutubePlayer = styled('iframe')({
  position: 'absolute',
  inset: 0,
  width: '100%',
  height: '100%',
  border: 0
})

const PlayerError = styled('div')({
  position: 'absolute',
  inset: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center'
})

const ContentContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  overflowY: 'auto',
  padding: theme.spacing(2),
  '&::-webkit-scrollbar': {
    width: '6px',
    height: '6px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.divider,
    borderRadius: '10px'
  }
}))

const ImageLink = styled(NextLink)({
  display: 'block',
  cursor: 'pointer'
})

const Image = styled(NativeImage)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  borderRadius: theme.spacing(2)
}))

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const contentMapping = {
  video: (contentItem) => ({
    source: contentItem.video,
    titleContent: contentItem.title
  }),
  youtube: (contentItem) => ({
    source: getYouTubeVideoId(contentItem.youtubeURL),
    titleContent: contentItem.title
  }),
  'image-swipe': (contentItem) => ({
    source: contentItem.imageList,
    titleContent: contentItem.title
  }),
  content: (contentItem) => ({
    source: contentItem.value,
    titleContent: contentItem.title
  })
}

const iconStyle = {
  fontSize: 'small',
  color: 'primary',
  sx: {
    borderRadius: '50%',
    bgcolor: 'primary.lighter',
    marginLeft: 1,
    width: 20,
    height: 20,
    padding: '4px'
  }
}

const ICON_MAP = {
  video: <OndemandVideoRounded {...iconStyle} />,
  youtube: <OndemandVideoRounded {...iconStyle} />,
  'image-swipe': <InsertPhotoOutlined {...iconStyle} />,
  content: <TextSnippetOutlined {...iconStyle} />
}
// ----------------------------------------------------------------------

/**
 * @param {Object} props
 * @param {Object} props.library
 * @param {Object} props.page
 * @param {Object} props.product
 * @param {string} props.title
 * @param {Object[]} props.items
 * @returns {JSX.Element}
 */
export default function ContentPageClassTrainingV2({
  title,
  sessions,
  page,
  description
}) {
  const { _ } = useLingui()

  const [currentContent, setCurrentContent] = useState({
    type: '',
    source: null,
    titleContent: ''
  })
  const [autoPlay, setAutoPlay] = useState(false)

  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    const firstContentItem = sessions
      .flatMap((session) => session.list)
      .find((item) =>
        ['video', 'youtube', 'image-swipe', 'content'].includes(item.type)
      )

    if (firstContentItem) {
      const { source = null, titleContent = null } =
        contentMapping[firstContentItem.type]?.(firstContentItem) || {}

      setCurrentContent({
        type: firstContentItem.type,
        source,
        titleContent
      })
    }
  }, [sessions])

  const handleContentSelect = (type, source, titleContent) => {
    setCurrentContent({
      type,
      source: type === 'youtube' ? getYouTubeVideoId(source) : source,
      titleContent
    })
    setAutoPlay(true)
  }

  /**
   * Renders the appropriate content based on the current content type.
   *
   * @returns {React.ReactNode} The rendered content
   */
  function showContent() {
    if (!currentContent.source) return null

    if (currentContent.type === 'video') {
      return (
        <Box marginBottom={2}>
          <VideoPlayer
            fileId={currentContent.source}
            accessContext={{
              contextType: 'ContentPage',
              contextId: page._id
            }}
            autoPlay={autoPlay ? 'any' : false}
          />
        </Box>
      )
    }

    if (currentContent.type === 'youtube') {
      return (
        <ContentArea>
          {currentContent.source ? (
            <YoutubePlayer
              id={`video-${currentContent.source}`}
              key={`video-${currentContent.source}`}
              type='text/html'
              src={`https://www.youtube-nocookie.com/embed/${currentContent.source}?enablejsapi=1&origin=${origin}${autoPlay ? '&autoplay=1' : ''}`}
              allowFullScreen
            />
          ) : (
            <PlayerError>
              <VideocamOffOutlined fontSize='large' />
              <Typography variant='shy'>
                There is a problem when trying to play the video. <br />
                (ERROR: INVALID_VIDEO_ID)
              </Typography>
            </PlayerError>
          )}
        </ContentArea>
      )
    }

    if (currentContent.type === 'image-swipe') {
      return (
        <Stack spacing={1}>
          {currentContent.titleContent && (
            <Typography fontWeight='medium'>
              {currentContent.titleContent}
            </Typography>
          )}

          <GridCarousel
            isPersistEdgeNavOnMobile
            isEnableDotNav={currentContent.source.length > 1}
            isEnableEdgeNav={currentContent.source.length > 1}
          >
            {currentContent.source.map(({ imageURL, linkURL }, imageNo) =>
              linkURL ? (
                <ImageLink
                  key={`image-no-${imageNo}`}
                  href={linkURL}
                >
                  <ImageLink
                    src={imageURL ?? fallbackImage.grey300Pixel}
                    alt={`Image ${imageNo} for ${title}`}
                  />
                </ImageLink>
              ) : (
                <div key={`image-no-${imageNo}`}>
                  <Image
                    src={imageURL ?? fallbackImage.grey300Pixel}
                    alt={`Image ${imageNo} for ${title}`}
                  />
                </div>
              )
            )}
          </GridCarousel>
        </Stack>
      )
    }

    if (currentContent.type === 'content') {
      return (
        <ContentArea
          bgcolor='white'
          borderRadius='0'
          sx={{
            borderTop: 1,
            borderBottom: 1,
            borderColor: 'divider'
          }}
        >
          <ContentContainer>
            <Stack spacing={1}>
              {currentContent.titleContent && (
                <Typography fontWeight='medium'>
                  {currentContent.titleContent}
                </Typography>
              )}
              <TextWithSoftBreaks>{currentContent.source}</TextWithSoftBreaks>
            </Stack>
          </ContentContainer>
        </ContentArea>
      )
    }
    return null
  }

  return (
    <ContentPageSection title={title}>
      <Box>
        {description && (
          <Box sx={{ marginBottom: 1 }}>
            <TextWithSoftBreaks>{description}</TextWithSoftBreaks>
          </Box>
        )}
      </Box>

      <Stack spacing={1.5}>
        {showContent()}
        <Box
          sx={{
            borderTop: 1,
            borderBottom: 1,
            borderLeft: 1,
            borderRight: 1,
            borderColor: 'divider',
            marginRight: '0.5',
            borderRadius: 1,

            overflowY: 'auto',
            maxHeight: {
              xs: '50vh',
              md: '33vh'
            },
            '&::-webkit-scrollbar': {
              width: '6px',
              height: '6px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'divider',
              borderRadius: '10px'
            }
          }}
        >
          {sessions.map((session, sessionIdx) => (
            <div
              key={`session-${sessionIdx}`}
              style={{
                marginTop: 8,
                marginBottom: 8
              }}
            >
              <Accordion
                disableGutters
                // variant='outlined'
                elevation={0}
                sx={{
                  borderColor: 'grey.300',
                  marginRight: 0.5,
                  '&.Mui-expanded': {
                    boxShadow: 'none'
                  }
                }}
                expanded={expanded === `panel-${sessionIdx}`}
                onChange={handleChange(`panel-${sessionIdx}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  aria-controls={`session-${sessionIdx}-content`}
                  id={`session-${sessionIdx}-header`}
                >
                  <Typography fontWeight='bold'>
                    {_(msg`Sesi ${sessionIdx + 1}:`)} {session.title}
                  </Typography>

                  <Typography>{session.description}</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Stack spacing={1.5}>
                    {session.list.map((item, index) => (
                      <div key={item.id}>
                        <Box
                          sx={{
                            margin: '-6px 0',
                            borderTop: index === 0 ? 1 : 0,
                            borderColor: 'divider'
                          }}
                        >
                          <ListItem
                            divider={session.list.length > 1}
                            disablePadding
                            disableGutters
                          >
                            {[
                              'youtube',
                              'video',
                              'image-swipe',
                              'content'
                            ].includes(item.type) ? (
                              <SessionItemContent
                                item={item}
                                currentContent={currentContent}
                                onSelect={handleContentSelect}
                              />
                            ) : item.type === 'link' ? (
                              <SessionItemLink item={item} />
                            ) : item.type === 'file' ? (
                              <SessionItemFile
                                item={item}
                                contentPage={page}
                              />
                            ) : null}
                          </ListItem>
                        </Box>
                      </div>
                    ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </Box>
      </Stack>
    </ContentPageSection>
  )
}

// ----------------------------------------------------------------------

/**
 *
 * @param {Object} props - The component props.
 * @param {Object} props.item - The Content item object.
 * @param {string} props.item.type - The type of the Content item ('video' or 'youtube').
 * @param {string} props.item.video - The video source for a video item.
 * @param {string} props.item.youtubeURL - The YouTube URL for a YouTube video item.
 * @param {Object} props.currentContent - The currently selected Content item.
 * @param {Function} props.onSelect - The function to call when the Content item is selected.
 * @returns {JSX.Element} - The rendered list item button.
 */
function SessionItemContent({ item, currentContent, onSelect }) {
  /**
   *
   * @param {Object} item - The content item to check.
   * @param {Object} content - The currently selected content.
   * @returns {boolean} - True if the content item is selected, false otherwise.
   */
  function isContentSelected(item, content) {
    const contentSelection = {
      video: item.video === content.source,
      youtube: getYouTubeVideoId(item.youtubeURL) === content.source,
      'image-swipe': item.imageList === content.source,
      content: item.value === content.source
    }

    return contentSelection[item.type] || false
  }

  const isSelected =
    item.type === currentContent.type && isContentSelected(item, currentContent)

  const getContentIcon = (type) => {
    return ICON_MAP[type] || null
  }

  return (
    <ListItemButton
      onClick={() => {
        const contentMap = {
          video: { source: item.video, titleContent: item.title },
          youtube: { source: item.youtubeURL, titleContent: item.title },
          'image-swipe': { source: item.imageList, titleContent: item.title },
          content: { source: item.value, titleContent: item.title }
        }

        const { source = null, titleContent = null } =
          contentMap[item.type] || {}
        onSelect(item.type, source, titleContent)
      }}
      selected={isSelected}
    >
      <ListItemText>
        <Stack
          direction='row'
          alignItems='center'
        >
          {item.title}
          {getContentIcon(item.type)}
        </Stack>
      </ListItemText>
    </ListItemButton>
  )
}

/**
 * @param {Object} props
 * @param {Object} props.item
 * @param {Object} props.contentPage
 * @returns {JSX.Element}
 */
function SessionItemFile({ item, contentPage }) {
  return (
    <ListItemButton>
      <AccessFileButton
        fullWidth
        fileId={item.file}
        contextType='ContentPage'
        contextId={contentPage._id}
        sx={{
          all: 'initial',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          color: 'inherit',
          width: '100%',
          padding: 0,
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }}
      >
        <ListItemText
          primaryTypographyProps={{
            noWrap: true
          }}
        >
          <Stack
            direction='row'
            alignItems='center'
          >
            {item.title}
            <AttachFileRounded {...iconStyle} />
          </Stack>
        </ListItemText>
      </AccessFileButton>
      <ListItemIcon>
        <OpenInNew
          sx={{ width: 24, height: 24 }}
          color='disabled'
        />
      </ListItemIcon>
    </ListItemButton>
  )
}

/**
 * @param {Object} props
 * @param {Object} props.item
 * @returns {JSX.Element}
 */
function SessionItemLink({ item }) {
  return (
    <ListItemButton
      component='button'
      variant='text'
      LinkComponent='a'
      href={item.linkURL}
      target='_blank'
    >
      <ListItemText
        primaryTypographyProps={{
          noWrap: true
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
        >
          {item.title}
          <InsertLinkRounded {...iconStyle} />
        </Stack>
      </ListItemText>
      <ListItemIcon>
        <OpenInNew
          sx={{ width: 24, height: 24 }}
          color='disabled'
        />
      </ListItemIcon>
    </ListItemButton>
  )
}
